<template>
  <div>
    <div class="card">
      <div class="card-body">
        <!-- <div class="row">
          <div class="col">
            <div
              class="bg-hpcolour-orange text-center p-3 mb-3"
              style="font-size: 16px; background-color: #ff9d2a"
            >
              <p class="p-0 m-0">
                Please note pharmacies prioritise NHS vaccinations in September
                and the corporate flu season begins mid September, so the
                function may only be available from this time.
              </p>
            </div>
          </div>
        </div> -->

        <div class="row">
          <div class="col-xl-4 col-12">
            <div class="row" v-if="error">
              <div class="col">
                <div class="async-error">{{ errorMessage }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <h3 class="text-hpcolour-orange">Find a pharmacy</h3>
                <b-form inline class="mr-3" @submit="onSubmit">
                  <b-button-group>
                    <b-form-input
                      v-model="postcode"
                      class="form-control form-control-lg"
                      placeholder="Enter postcode"
                    ></b-form-input>
                    <b-button @click="find" class="btn btn-lg btn-green"
                      >Find</b-button
                    >
                  </b-button-group>
                </b-form>
                <b-link
                  @click="findMe()"
                  style="font-size: large"
                  class="text-nowrap"
                >
                  <img
                    src="/images/marker-icon.png"
                    class="pr-1"
                    style="max-height: 18px"
                  />
                  Use my location
                </b-link>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col">
                <h3 class="text-hpcolour-orange">Filter your search by</h3>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-xl-12 col-4">
                <div class="row mt-2">
                  <div
                    class="col-xl-6 col-12 d-flex align-items-center justify-content-xl-start justify-content-center"
                  >
                    <img
                      :src="parkingIcon"
                      height="34px"
                      alt="Parking available"
                      title="Parking available"
                    />
                    <span class="ml-1">Parking</span>
                  </div>
                  <div
                    class="col-xl-6 col-12 d-flex align-items-center mt-xl-0 mt-2 justify-content-xl-start justify-content-center"
                  >
                    <label class="code-switch" @click="toggleParking">
                      <span :class="parkingSwitchClass"></span>
                    </label>
                  </div>
                </div>
                <!-- <span
                  class="d-xl-none d-flex align-items-center mt-xl-0 mt-2 justify-content-xl-start justify-content-center"
                  style="color: red"
                  >Drop in available mid Sept</span
                > -->
              </div>

              <div class="col-xl-12 col-4">
                <div class="row mt-2">
                  <div
                    class="col-xl-6 col-12 d-flex align-items-center justify-content-xl-start justify-content-center"
                  >
                    <img
                      :src="openNowIcon"
                      height="34px"
                      alt="Open now"
                      title="Open now"
                    />
                    <span class="ml-1">Open now</span>
                  </div>
                  <div
                    class="col-xl-6 col-12 d-flex align-items-center mt-xl-0 mt-2 justify-content-xl-start justify-content-center"
                  >
                    <label class="code-switch" @click="toggleOpenNow">
                      <span :class="openNowSwitchClass"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="col-xl-12 col-4">
                <div class="row mt-2">
                  <div
                    class="col-xl-6 col-12 d-flex align-items-center justify-content-xl-start justify-content-center"
                  >
                    <img
                      :src="dropInIcon"
                      height="34px"
                      alt="Drop in available"
                      title="Drop in available"
                    />
                    <span class="ml-1">Drop in</span>
                  </div>
                  <div
                    class="col-xl-6 col-12 d-flex align-items-center mt-xl-0 mt-2 justify-content-xl-start justify-content-center"
                  >
                    <label class="code-switch" @click="toggleDropIn">
                      <span :class="dropInSwitchClass"></span>
                    </label>
                  </div>
                </div>
                <!-- <span class="d-none d-xl-block mt-1" style="color: red"
                  >Drop in available mid Sept</span
                > -->
              </div>
            </div>
          </div>
          <div class="col-xl-8 col-12">
            <div ref="map" style="height: 600px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import data from "../data/pharmacy.js";
import axios from "axios";
import { DateTime } from "luxon";
import * as PostcodeLookup from "./../modules/PostcodeLookup";
import $ from "jquery";

import { mapGetters } from "vuex";

//https://flupharmacy2021.s3.eu-west-2.amazonaws.com/branches-cache.json
//https://d1hyoy98ecrfba.cloudfront.net/branches-cache.json

const branchesCacheUrl =
  "https://pharmacyassets.digitalflu.co.uk/branches-cache.json"; // live
//const branchesCacheUrl = "https://flupharmacy2021.s3.eu-west-2.amazonaws.com/dev-branches-cache.json"; // dev

export default {
  data() {
    return {
      pharmacies: [],

      map: null,
      tileLayer: null,
      markerGroup: null,

      now: DateTime.now(),

      postcode: null,
      dropin: false,
      parking: false,
      opennow: false,

      error: false,
      errorMessage: null,
    };
  },
  mounted() {
    // Get postcode from store, clear it too??
    if (this.findPostcode !== null) {
      this.postcode = this.findPostcode;
    }

    this.initMap();
    setTimeout(() => {
      this.initLocation();
      setTimeout(() => {
        this.buildPharmacies();
        if (this.postcode !== null) {
          setTimeout(() => {
            this.find();
          }, 100);
        }
      }, 100);
    }, 100);

    // update time ( every 10 seconds )

    setInterval(() => {
      this.now = DateTime.now();
      //console.log(self.now.weekday, self.now.hour, self.now.minute);
    }, 10000);

    //buildPharmacies

    $(document).ready(function () {
      $(document).on("click", "a.confirm-leaving", function (e) {
        e.preventDefault();
        let linkObj = $(this);
        let link = linkObj[0];
        // console.log(link.href, linkObj);
        let extraText = linkObj.data("extratext");

        Swal.fire({
          title: "You are about to leave this site",
          html:
            extraText ||
            "You are about to leave this site. Dears Pharmacy are not responsible for the content of third party sites.",
          showCancelButton: true,
          confirmButtonText: `Yes`,
          cancelButtonText: `No, Close`,
        }).then((result) => {
          if (result.value) {
            //window.location = link.href;
            window.open(link.href, "_blank");
          }
        });
      });
    });
  },

  computed: {
    ...mapGetters({
      findPostcode: "findForm/postcode",
    }),

    markers: function () {
      let markers = [];

      for (let i = 0; i < this.pharmacies.length; i++) {
        let pharmacy = this.pharmacies[i];
        let title = pharmacy.title ? pharmacy.title : "";
        let label = pharmacy.label ? pharmacy.label : "";
        if (pharmacy.latitude !== null && pharmacy.longitude !== null) {
          let canShow = true;

          if (this.dropin && !pharmacy.dropin) {
            canShow = false;
          }

          if (this.parking && !pharmacy.parking) {
            canShow = false;
          }

          if (this.opennow) {
            let pharmacyTimes = pharmacy.openingtimes[this.now.weekday - 1];
            if (pharmacyTimes == null) {
              canShow = false;
            } else {
              let nowTime = [this.now.hour, this.now.minute];
              let openTime = pharmacyTimes[0];
              let closeTime = pharmacyTimes[1];

              if (
                !(
                  this.timeIsPast(nowTime, openTime) &&
                  this.timeIsBefore(nowTime, closeTime)
                )
              ) {
                canShow = false;
              }
            }
          }

          if (canShow) {
            let marker = L.marker(
              L.latLng(pharmacy.latitude, pharmacy.longitude),
              {
                title: title,
              }
            );
            marker.bindPopup(label, {
              minWidth: 400,
              maxWidth: 500,
            });
            markers.push(marker);
          }
        }
      }

      return markers;
    },

    dropInIcon: function () {
      return (
        "/images/" + (this.dropin === false ? "Grey-" : "") + "Drop-In-Icon.png"
      );
    },

    parkingIcon: function () {
      return (
        "/images/" +
        (this.parking === false ? "Grey-" : "") +
        "Parking-Icon.png"
      );
    },

    openNowIcon: function () {
      return (
        "/images/" +
        (this.opennow === false ? "Grey-" : "") +
        "Open-Now-Icon.png"
      );
    },

    dropInSwitchClass: function () {
      return {
        slider: true,
        orange: true,
        round: true,
        checked: this.dropin,
      };
    },

    parkingSwitchClass: function () {
      return {
        slider: true,
        orange: true,
        round: true,
        checked: this.parking,
      };
    },

    openNowSwitchClass: function () {
      return {
        slider: true,
        orange: true,
        round: true,
        checked: this.opennow,
      };
    },
  },

  methods: {
    toggleDropIn() {
      // this.showLoadingPopup();
      // setTimeout(() => {
      //   this.dropin = !this.dropin;
      // }, 100);
    },

    toggleParking() {
      this.showLoadingPopup();
      setTimeout(() => {
        this.parking = !this.parking;
      }, 100);
    },

    toggleOpenNow() {
      this.showLoadingPopup();
      setTimeout(() => {
        this.opennow = !this.opennow;
      }, 100);
    },

    timeIsPast(time, threshold) {
      //console.log('timeIsPast', time, threshold);
      return (
        (time[0] == threshold[0] && time[1] >= threshold[1]) ||
        time[0] > threshold[0]
      );
    },

    timeIsBefore(time, threshold) {
      //console.log('timeIsBefore', time, threshold);
      return (
        (time[0] == threshold[0] && time[1] <= threshold[1]) ||
        time[0] < threshold[0]
      );
    },

    initMap() {
      this.map = L.map(this.$refs.map).setView([54, -2.5], 6);
      this.tileLayer = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          maxZoom: 19,
          minZoom: 6,
          attribution:
            '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }
      );
      this.tileLayer.addTo(this.map);
    },

    initLocation() {
      this.markerGroup = L.markerClusterGroup({
        chunkedLoading: true,
      });

      this.markerGroup.addLayers(this.markers);
      this.map.addLayer(this.markerGroup);

      let _this = this;
      this.map.on("popupopen", function (e) {
        let markerContent = e.popup._source._popup._content;
        _this.showMapMarkerPopup(markerContent);
      });
    },

    updateLocations() {
      this.markerGroup.clearLayers();
      this.markerGroup.addLayers(this.markers);
      Swal.close();
    },

    showLoadingPopup() {
      Swal.fire({
        title: "Loading",
        text: "Please wait",
        //allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
    },

    getViewport() {
      // https://stackoverflow.com/a/8876069
      const width = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
      if (width < 576) return "xs";
      if (width < 768) return "sm";
      if (width < 992) return "md";
      if (width < 1200) return "lg";
      return "xl";
    },

    showMapMarkerPopup(markerContent) {
      if (this.getViewport() === "xs") {
        this.map.closePopup();
        Swal.fire({
          html: markerContent,
          showConfirmButton: false,
          showCloseButton: true,
          title: "Information",
        });
      }
    },

    buildPharmacies() {
      this.showLoadingPopup();
      axios
        .get(branchesCacheUrl)
        .then((response) => {
          const pharmacies = response.data.filter(function (pharmacy) {
            return pharmacy.title.includes("Dears");
          });
          this.pharmacies = pharmacies;
          //const geoData = response.data.result;
          //that.map.flyTo([geoData.latitude, geoData.longitude], 11);
          Swal.close();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    find() {
      const that = this;
      this.error = false;
      PostcodeLookup.postcodeLookup(this.postcode)
        .then((response) => {
          const geoData = response;
          that.map.flyTo([geoData.latitude, geoData.longitude], 12);
        })
        .catch((error) => {
          this.errorMessage = error;
          this.error = true;
        });
    },

    findMe() {
      const that = this;
      if (navigator.geolocation) {
        var startPos;
        var geoSuccess = function (position) {
          startPos = position;
          const maptarget = [
            startPos.coords.latitude,
            startPos.coords.longitude,
          ];
          const maptargetZoom = 11;
          that.map.flyTo(maptarget, maptargetZoom);
        };
        navigator.geolocation.getCurrentPosition(geoSuccess);
      }
    },

    onSubmit(event) {
      event.preventDefault();
      this.find();
    },
  },

  watch: {
    markers: function (val) {
      this.updateLocations();
    },
  },
};
</script>

<style>
</style>

import { API } from "aws-amplify";
export function postcodeLookup(postcode) {
  if (!postcode) {
    return false;
  }
  const filteredPostcode = postcode.trim().toLowerCase().replace(/\s/g, "");
  const postcode_lookup = /* GraphQL */ `
    query Postcode_lookup($postcode: String!) {
      postcode_lookup(postcode: $postcode) {
        id
        latitude
        longitude
      }
    }
  `;
  var output = new Promise(async function (resolve, reject) {
    const {
      data: { postcode_lookup: lookup },
    } = await API.graphql({
      query: postcode_lookup,
      variables: {
        postcode: filteredPostcode,
      },
      authMode: "API_KEY",
    });
    if (lookup === null) {
      reject("Postcode not recognised, please try again.");
    }
    return resolve(lookup);
  });
  return output;
};